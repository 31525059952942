<template>
  <div class="tab-content" id="PTtab-1" :key="'PTtab-1'" >
    <div class="pagenamPT">
      <h3 class="pagename2">파트너{{$t('front.stributor.totalList')}}</h3>
    </div>
    <div class="sch_PC">
      <div class="PTsch flex-c">
        <div class="datesearchPTWarp flex-c gap-2">
          <h4>날짜</h4>
          <date-filter :retail="true" @search="loadMainTable(1)"
                     @update="onChangeDateTable"
                     :defaultDay="0"
                     :startDate="mainTableDate.startDate"
                     :endDate="mainTableDate.endDate"
                     :isOldYn="true"
                     :id="'main-date-checkbox1'"
                     @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
        </div>
      </div>
    </div>
    <div class="sch_M">
      <div class="PTsch flex-c">
        <div class="datesearchM">
          <date-filter-mobile :retail="true" @search="loadMainTable(1)"
                          @update="onChangeDateTable"
                          :defaultDay="0"
                          :startDate="mainTableDate.startDate"
                          :endDate="mainTableDate.endDate"
                          :isOldYn="true"
                          :id="'main-date-checkbox1'"
                          @setOldYn="setOldYn" :oldYn="reqData.oldYn"/>
        </div>
      </div>
    </div>
    <div class="sch_Normal">
      <div class="sch_Btm">
        <div class="searchPTwrap">
          <div class="searchPT w30p">
            <div class="datesearchPT flex-c gap-2">
              <h4>구분</h4>
              <select class="h36px">
                <option value="memId" selected>아이디</option>
              </select>
            </div>
          </div>
          <div class="searchPT">
            <input type="text" :placeholder="$t('front.search.emptySearch')" v-model="searchMemId" class="h36px"/>
            <a @click="loadMainTable(1)" class="sch_Icon">
              <img src="@/assets/img/search.png" alt=""/>
            </a>
          </div>
        </div>
      </div>
    </div>
    <ul class="strbtnPT mb20">
      <li>{{$t('front.common.deposit')}}<span>{{thousand(total.userCashIn || 0)}}</span></li>
      <li>{{$t('front.common.withdrawal')}}<span>{{thousand(total.userCashOut || 0)}}</span></li>
      <li>입출차액<span>{{thousand(total.userCashResultAmt || 0)}}</span></li>
      <li>{{$t('front.stributor.m22')}}<span>{{thousand(total.betAmt || 0)}}</span></li>
      <li>{{$t('front.stributor.m23')}}<span>{{thousand(total.betAmtWin || 0)}}</span></li>
      <li>{{$t('front.stributor.winlose')}}<span>{{thousand(total.betResultAmt || 0)}}</span></li>
      <!--li class="saveBtnPT" @click="move = true">{{$t('front.stributor.move')}}</li-->
    </ul>
      <div class="line"><retail-main-table v-model:partnerObj=partnerLevelObject :list="list" :date="mainTableDate" :table="'main'" :mainPageInfo=pageInfo  @goToMainPage="loadMainTable" /></div>

    <div v-if="move" class="moveWrap makeWrap">
      <div class="makeWraphead">
      <h4>요율조정</h4>
        <a @click="move=!move" class="close"><img src="@/assets/img/icon_cancelW.svg" /></a>
      </div>
      <div class="makeWrapbody">
         <div>
           <p class="name">내요율</p>
           <table class="rolllose">
             <!--tr>
               <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.roll')}}(%)</th>
               <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.lose')}}(%)</th>
               <th :colspan="Object.keys(gameCount).length">{{$t('front.stributor.roll')}}(%) </th>
               <th :colspan="Object.keys(gameCount).length">{{$t('front.stributor.lose')}}(%)</th>
             </tr-->
             <tr>
               <th></th>
               <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
               <th v-if="gameCount['hc-casino']">{{$t('front.gnb.hotelcasino')}}</th>
               <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
               <th v-if="gameCount['sports']">{{$t('front.gnb.sport')}}</th>
               <th v-if="gameCount['mini-game'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
               <!--th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
               <th v-if="gameCount['hc-casino']">{{$t('front.gnb.hotelcasino')}}</th>
               <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
               <th v-if="gameCount['sports']">{{$t('front.gnb.sport')}}</th>
               <th v-if="gameCount['mini-game'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th-->
             </tr>
             <tr>
               <th>{{$t('front.stributor.roll')}}(%)</th>
               <td v-if="gameCount['casino']">{{myRate.casinoPR}}</td>
               <td v-if="gameCount['hc-casino']">{{myRate.hcasinoPR}}</td>
               <td v-if="gameCount['slot']">{{myRate.slotPR}}</td>
               <td v-if="gameCount['sports']">{{myRate.sportPR}}</td>
               <td v-if="gameCount['mini-game'] || gameCount['mini game']">{{myRate.miniPR}}</td>
             </tr>
             <tr>
               <th>{{$t('front.stributor.lose')}}(%)</th>
               <td v-if="gameCount['casino']">{{myRate.casinoLR}}</td>
               <td v-if="gameCount['hc-casino']">{{myRate.hcasinoLR}}</td>
               <td v-if="gameCount['slot']">{{myRate.slotLR}}</td>
               <td v-if="gameCount['sports']">{{myRate.sportLR}}</td>
               <td v-if="gameCount['mini-game'] || gameCount['mini game']">{{myRate.miniLR}}</td>
             </tr>
           </table>
        </div>
        <div>
          <p class="name">하부회원 요율조정</p>
          <div class="scroll">
            <table class="rolllose2">
              <tr>
                <th>{{$t('front.common.memId')}}<em>({{$t('front.common.nickName')}})</em></th>
                <th>분류</th>
                <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                <th v-if="gameCount['hc-casino']">{{$t('front.gnb.hotelcasino')}}</th>
                <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                <th v-if="gameCount['sports']">{{$t('front.gnb.sport')}}</th>
                <th v-if="gameCount['mini-game'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
                <!--th :colspan="Object.keys(gameCount).length">{{$t('front.stributor.roll')}}(%)</th>
                <th :colspan="Object.keys(gameCount).length">{{$t('front.stributor.lose')}}(%)</th>
                <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.roll')}}(%)</th>
                <th :colspan="Object.keys(commonCodeByOrder).length">{{$t('front.stributor.lose')}}(%)</th> -->
              </tr>
              <!--tr>
                <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                <th v-if="gameCount['hc-casino']">{{$t('front.gnb.hotelcasino')}}</th>
                <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                <th v-if="gameCount['sports']">{{$t('front.gnb.sport')}}</th>
                <th v-if="gameCount['mini-game'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
                <th v-if="gameCount['casino']">{{$t('front.gnb.casino')}}</th>
                <th v-if="gameCount['hc-casino']">{{$t('front.gnb.hotelcasino')}}</th>
                <th v-if="gameCount['slot']">{{$t('front.gnb.slot')}}</th>
                <th v-if="gameCount['sports']">{{$t('front.gnb.sport')}}</th>
                <th v-if="gameCount['mini-game'] || gameCount['mini game']">{{$t('front.gnb.minigame')}}</th>
              </tr-->
              <template v-if="myChildrenRate.length">
                <template v-for="item in myChildrenRate" :key="item.memId">
                  <tr>
                    <td rowspan="2">{{item.memId}}<em>({{item.memNick}})</em></td>
                    <th>{{$t('front.stributor.roll')}}(%)</th>
                    <td v-if="gameCount['casino']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.casinoPRError}" type="text" v-model="item.casinoPR" :disabled="userData.updId == 'royal77'"></td>
                    <td v-if="gameCount['hc-casino']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.hcasinoPRError}" type="text" v-model="item.hcasinoPR" :disabled="userData.updId == 'royal77'"></td>
                    <td v-if="gameCount['slot']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.slotPRError}" type="text" v-model="item.slotPR" :disabled="userData.updId == 'royal77'"></td>
                    <td v-if="gameCount['sports']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.sportPRError}" type="text" v-model="item.sportPR" :disabled="userData.updId == 'royal77'"></td>
                    <td v-if="gameCount['mini-game']">
                      <!-- <input @change="onChangeChildrenRate(item)" :class="{'error': item.miniPRError}" type="text" v-model="item.miniPR"> -->
                      <button class="detailSet" @click="toggleDetailSet(item)">세부설정</button>
                    </td>
                    <div class="detailSetWrap" v-if="isSelectedRow(item)">
                      <table>
                        <tbody>
                          <tr>
                            <td rowspan="2" class="tableheadside">일반</td>
                            <td>롤링</td>
                            <td>
                             <button>최소</button>
                             <input>
                             <button>최대</button>
                            </td>
                          </tr>
                          <tr>
                            <td>롤링</td>
                            <td>
                             <button>최소</button>
                             <input>
                             <button>최대</button>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2" class="tableheadside">조합</td>
                            <td>루징</td>
                            <td>
                             <button>최소</button>
                             <input>
                             <button>최대</button>
                            </td>
                          </tr>
                          <tr>
                            <td>루징</td>
                            <td>
                             <button>최소</button>
                             <input>
                             <button>최대</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button class="detailSetSave">저장</button>
                      <span @click="toggleDetailSet" class="detailClose">×</span>
                    </div>
                  </tr>
                  <tr>
                    <th>{{$t('front.stributor.lose')}}(%)</th>
                    <td v-if="gameCount['casino']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.casinoLRError}" type="text" v-model="item.casinoLR" :disabled="userData.updId == 'royal77'"></td>
                    <td v-if="gameCount['hc-casino']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.hcasinoPRError}" type="text" v-model="item.hcasinoLR" :disabled="userData.updId == 'royal77'"></td>
                    <td v-if="gameCount['slot']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.slotLRError}" type="text" v-model="item.slotLR" :disabled="userData.updId == 'royal77'"></td>
                    <td v-if="gameCount['sports']"><input @change="onChangeChildrenRate(item)" :class="{'error': item.sportLRError}" type="text" v-model="item.sportLR" :disabled="userData.updId == 'royal77'"></td>
                    <td v-if="gameCount['mini-game']">
                      <!-- <input @change="onChangeChildrenRate(item)" :class="{'error': item.miniLRError}" type="text" v-model="item.miniLR"> -->
                      <button class="detailSet" @click="toggleDetailSetlosing(item)">세부설정</button>
                    </td>
                    <div class="detailSetWrap detailLosing" v-if="isSelectedRowlosing(item)">
                      <table>
                        <tbody>
                          <tr>
                            <td rowspan="2" class="tableheadside">일반</td>
                            <td>롤링</td>
                            <td>
                              <button>최소</button>
                              <input>
                              <button>최대</button>
                            </td>
                          </tr>
                          <tr>
                            <td>롤링</td>
                            <td>
                              <button>최소</button>
                              <input>
                              <button>최대</button>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2" class="tableheadside">조합</td>
                            <td>루징</td>
                            <td>
                              <button>최소</button>
                              <input>
                              <button>최대</button>
                            </td>
                          </tr>
                          <tr>
                            <td>루징</td>
                            <td>
                              <button>최소</button>
                              <input>
                              <button>최대</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button class="detailSetSave">저장</button>
                      <span @click="toggleDetailSetlosing" class="detailClose">×</span>
                    </div>
                  </tr>
                </template>
              </template>
            </table>

          </div>
        </div>
        <p>하부회원의 최대 요율은 내 상위요율을 넘을 수 없습니다. 최소요율은 해당회원의 하부 최대 요율보다 낮을 수 없습니다.</p>
        <div class="btnWrap">
          <a class="btn" @click="onUpdateChildrenRate">{{$t('front.stributor.save')}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  partnerLevels, retailMemRate, retailMyCalculate, retailUpdate
} from '@/api/retail'
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import RetailMainTable from '@/components/member/stributor/RetailMainTable.vue'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
export default {
  name: 'PartnerCash',
  components: {
    DateFilter,
    RetailMainTable,
    DateFilterMobile
  },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      mainTableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      reqData: {
        searchType: 'OUTER',
        chkTodayYn: 'N',
        startDate: '',
        endDate: '',
        offset: -1,
        oldYn: 'N'
      },
      list: [],
      pageInfo: {

      },
      move: false,
      make: false,
      total: {},
      searchType: 'P',
      partnerLevelList: [],
      partnerLevelObject: null,
      myRate: {},
      myChildrenRate: [],
      selectedRow: null,
      selectedRowlosing: null,
      detailSet: false,
      searchMemId: null
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.onLoadRate()
    this.getPartnerLevels()
    this.reqData.startDate = this.mainTableDate.startDate.split(' ')[0]
    this.reqData.endDate = this.mainTableDate.endDate.split(' ')[0]

    await this.loadMainTable()

    this.emitter.emit('Loading', false)
  },
  methods: {
    detailSetOpen () {
      this.detailSet = !this.detailSet
    },
    toggleDetailSet (item) {
      if (this.isSelectedRow(item)) {
        this.selectedRow = null
      } else {
        this.selectedRow = item
      }
    },
    isSelectedRow (item) {
      return this.selectedRow === item
    },
    toggleDetailSetlosing (item) {
      if (this.isSelectedRow(item)) {
        this.selectedRowlosing = null
      } else {
        this.selectedRowlosing = item
      }
    },
    isSelectedRowlosing (item) {
      return this.selectedRowlosing === item
    },
    onChangeChildrenRate (item) {
      const casinoPR = Number(item.casinoPR)
      if (casinoPR || casinoPR === 0) {
        const maxCasinoPR = Number(item.maxCasinoPR)
        const minCasinoPR = Number(item.minCasinoPR)
        if (maxCasinoPR < casinoPR || minCasinoPR > casinoPR) {
          item.casinoPRError = true
        } else {
          item.casinoPRError = false
        }
      }

      const hcasinoPR = Number(item.hcasinoPR)
      if (hcasinoPR || hcasinoPR === 0) {
        const maxHcasinoPR = Number(item.maxHcasinoPR)
        const minHcasinoPR = Number(item.minHcasinoPR)
        console.log(hcasinoPR, maxHcasinoPR, minHcasinoPR)
        if (maxHcasinoPR < hcasinoPR || minHcasinoPR > hcasinoPR) {
          item.hcasinoPRError = true
        } else {
          item.hcasinoPRError = false
        }
        console.log(item.hcasinoPRError)
      }

      const slotPR = Number(item.slotPR)
      if (slotPR || slotPR === 0) {
        const maxSlotPR = Number(item.maxSlotPR)
        const minSlotPR = Number(item.minSlotPR)
        if (maxSlotPR < slotPR || minSlotPR > slotPR) {
          item.slotPRError = true
        } else {
          item.slotPRError = false
        }
      }

      const miniPR = Number(item.miniPR)
      if (miniPR || miniPR === 0) {
        const maxMiniPR = Number(item.maxMiniPR)
        const minMiniPR = Number(item.minMiniPR)
        if (maxMiniPR < miniPR || minMiniPR > miniPR) {
          item.miniPRError = true
        } else {
          item.miniPRError = false
        }
      }

      const casinoLR = Number(item.casinoLR)
      if (casinoLR || casinoLR === 0) {
        const maxCasinoLR = Number(item.maxCasinoLR)
        const minCasinoLR = Number(item.minCasinoLR)
        if (maxCasinoLR < casinoLR || minCasinoLR > casinoLR) {
          item.casinoLRError = true
        } else {
          item.casinoLRError = false
        }
      }

      const hcasinoLR = Number(item.hcasinoLR)
      if (hcasinoLR || hcasinoLR === 0) {
        const maxHcasinoLR = Number(item.maxHcasinoLR)
        const minHcasinoLR = Number(item.minHcasinoLR)
        if (maxHcasinoLR < hcasinoLR || minHcasinoLR > hcasinoLR) {
          item.hcasinoLRError = true
        } else {
          item.hcasinoLRError = false
        }
      }

      const slotLR = Number(item.slotLR)
      if (slotLR || slotLR === 0) {
        const maxSlotLR = Number(item.maxSlotLR)
        const minSlotLR = Number(item.minSlotLR)
        if (maxSlotLR < slotLR || minSlotLR > slotLR) {
          item.slotLRError = true
        } else {
          item.slotLRError = false
        }
      }

      const miniLR = Number(item.miniLR)
      if (miniLR || miniLR === 0) {
        const maxMiniLR = Number(item.maxMiniLR)
        const minMiniLR = Number(item.minMiniLR)
        if (maxMiniLR < miniLR || minMiniLR > miniLR) {
          item.miniLRError = true
        } else {
          item.miniLRError = false
        }
      }
    },
    async onUpdateChildrenRate () {
      const rateList = this.myChildrenRate
      console.log(rateList)

      for (let i = 0, iLen = rateList.length; i < iLen; i++) {
        const item = rateList[i]
        if (item.casinoLRError ||
          item.casinoPRError ||
          item.hcasinoPRError ||
          item.hcasinoLRError ||
          item.slotLRError ||
          item.slotPRError ||
          item.miniLRError ||
          item.miniPRError
        ) {
          return
        }
      }

      const confirm = await this.onConfirm('front.common.confirmSave')
      if (confirm) {
        const param = {
          siteId: '',
          memId: '',
          rateList: rateList
        }

        this.emitter.emit('Loading', true)
        retailUpdate(param).then(res => {
          this.emitter.emit('Loading', false)
          const data = res.data
          if (data.resultCode === '0') {
            this.onCheck('front.recommender.complete')
            this.onLoadRate()
          }
        })
      }
    },
    onLoadRate () {
      retailMemRate({}).then(res => {
        const data = res.data
        console.log('retailMemRate : ', data)
        if (data.resultCode === '0') {
          this.myRate = data.data.myCategoryRate
          this.myChildrenRate = data.data.botCategoryRateList
        }
      })
    },
    thousand,

    setOldYn (data) {
      this.reqData.oldYn = data
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangeDateTable (value) {
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    async loadMainTable (page) {
      if (!page) {
        page = 1
      }
      this.emitter.emit('Loading', true)
      const params = {
        ...this.reqData,
        count_per_list: 20,
        page: page
      }
      console.log(params)

      if (this.searchMemId) {
        params.searchMemId = this.searchMemId
      }

      this.mainTableDate.startDate = this.reqData.startDate
      this.mainTableDate.endDate = this.reqData.endDate

      const today = new Date()
      if (params.endDate === getDateStr(today)) {
        params.chkTodayYn = 'Y'
      } else {
        params.chkTodayYn = 'N'
      }

      console.log('[req][retailMyCalculate] : ', params)
      await retailMyCalculate(params).then(res => {
        console.log('[res][retailMyCalculate] : ', res)
        window.scrollTo(0, 0)
        const data = res.data
        if (data.resultCode === '0') {
          this.list = data.data.outSearchList
          this.pageInfo = data.data.search
          this.total = data.data.outSearchTotal
        }

        this.emitter.emit('Loading', false)
      })
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/striNew.css"></style>
<style scoped>
  /* .datesearch {margin: 0;}
  .searchPTwrap { margin-bottom: 20px; display: flex; gap:20px; align-items: center;}
  .searchPTwrap label { font-size: 13px; margin-right: 5px;}
  .searchPTwrap input {border-radius: 5px; border: 1px solid #969696; height: 28px; box-sizing: border-box; text-indent: 5px; margin-right: 5px; }
  .searchPTwrap input.error {border: 1px solid #cc2121;}
  .searchPTwrap .idschbtn {background: #5068d4; border: 0; padding: 6px 8px; border-radius: 8px; vertical-align: middle; height: 28px;} */
  .sch_Btm {margin-bottom: 10px;}
</style>
